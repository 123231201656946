<template>
  <v-container class="full-height">
    <v-row class="full-height" align="center">
      <v-col cols="12">
        <preloader v-if="getting" text="Checking..." />

        <v-sheet
          v-if="!getting && invite == 'error'"
          max-width="650"
          class="mx-auto"
        >
          <v-alert type="error" border="left" text>
            Invalid invitation id
          </v-alert>
        </v-sheet>

        <v-card
          v-if="!getting && invite !== 'error'"
          max-width="650"
          class="mx-auto box-shadow"
          outlined
        >
          <v-card-title
            class="font-weight-bold justify-center secondary white--text"
          >
            <v-icon left dark>mdi-account-circle-outline</v-icon>
            <span>Create Your Account</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-10">
            <v-alert
              v-if="status.error"
              type="error"
              border="left"
              class="mb-7"
            >
              {{ status.error }}
            </v-alert>

            <v-form ref="signupForm" @submit.prevent="validateForm()">
              <v-text-field
                :rules="[rules.required]"
                v-model="data.firstName"
                label="First Name"
                outlined
                dense
              ></v-text-field>

              <v-text-field
                :rules="[rules.required]"
                v-model="data.lastName"
                label="Last Name"
                outlined
                dense
              ></v-text-field>

              <v-select
                :rules="[rules.required]"
                :items="items"
                v-model="role"
                label="Choose user type"
                dense
                outlined
              >
              </v-select>

              <v-select
                :rules="[rules.required]"
                :items="items1"
                v-if="role == 'MEALZEE for Students'"
                v-model="role1"
                label="Choose profession type"
                dense
                outlined
              >
              </v-select>
              <v-select
                :rules="[rules.required]"
                :items="confOrgs"
                v-if="role == 'MEALZEE for Students'"
                v-model="data.organisation"
                item-text="name"
                return-object
                label="MEALZEE Training Partners"
                dense
                outlined
              >
              </v-select>

              <v-radio-group
                v-show="role"
                v-model="data.gender"
                :label="stupidity ? 'Gender: ' : 'Gender assigned at birth: '"
                :rules="[rules.required]"
                class="mt-0"
                dense
                row
              >
                <v-radio label="Male" value="male"></v-radio>
                <v-radio label="Female" value="female"></v-radio>
                <v-radio
                  v-show="stupidity"
                  label="Non-binary"
                  value="non-binary"
                ></v-radio>
                <v-radio
                  v-show="stupidity"
                  label="Rather not say"
                  value="rather not say"
                ></v-radio>
              </v-radio-group>

              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
                v-model="menu"
                ref="menu"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :rules="[rules.required]"
                    v-model="data.birthDate"
                    label="Birth Date"
                    v-on="on"
                    readonly
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  :max="new Date().toISOString().substr(0, 10)"
                  v-model="data.birthDate"
                  min="1950-01-01"
                  ref="picker"
                ></v-date-picker>
              </v-menu>

              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                v-if="role == 'MEALZEE for Students'"
                min-width="290px"
                v-model="menu1"
                ref="menu"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :rules="[rules.required]"
                    v-model="data.gradDate"
                    label="Graduation Date"
                    v-on="on"
                    readonly
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.gradDate"
                  ref="picker1"
                ></v-date-picker>
              </v-menu>

              <v-text-field
                :rules="[rules.required, rules.email]"
                label="Email Address"
                v-model="data.email"
                :readonly="!!invite"
                outlined
                dense
              ></v-text-field>

              <v-text-field
                :rules="[rules.required]"
                v-model="data.phone"
                label="Phone Number"
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-if="role == 'MEALZEE for Dietitians'"
                label="APD / Dietitian Registration Number"
                :rules="[rules.required]"
                v-model="data.apd"
                outlined
                dense
              ></v-text-field>

              <!-- <v-text-field
                label="Associated Gym or Trainer (if applicable)"
                v-model="data.gym"
                v-if="!invite"
                outlined
                dense
              ></v-text-field> -->

              <v-radio-group
                v-model="data.energyUnit"
                :rules="[rules.required]"
                label="Energy unit:"
                class="mt-0"
                row
              >
                <v-radio label="Kilojoule" value="kj" dense></v-radio>
                <v-radio label="Calorie" value="cal" dense></v-radio>
              </v-radio-group>

              <v-text-field
                prepend-inner-icon="mdi-account-box-outline"
                label="APD / Dietician Registration Number"
                :rules="[rules.required]"
                v-model="data.apd"
                v-if="invite"
                outlined
              ></v-text-field>

              <v-text-field
                label="Qualification"
                placeholder="i.e. Bachelor of Nutrition"
                :rules="[rules.required]"
                v-model="data.qualification"
                v-if="
                  role == 'MEALZEE for Nutritionists' ||
                  role == 'MEALZEE for Naturopaths'
                "
                outlined
              ></v-text-field>

              <v-text-field
                prepend-inner-icon="mdi-account-box-outline"
                label="Registration Number"
                :rules="[rules.required]"
                v-model="data.reg_num"
                v-if="
                  role == 'MEALZEE for Nutritionists' ||
                  role == 'MEALZEE for Naturopaths'
                "
                outlined
              ></v-text-field>

              <v-text-field
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off-outline'"
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
                :rules="[rules.required, rules.password]"
                v-model="data.password"
                label="Password"
                outlined
                dense
              ></v-text-field>

              <v-text-field
                :rules="[
                  rules.required,
                  () =>
                    data.password == data.repeatPassword ||
                    'Password do not match',
                ]"
                v-model="data.repeatPassword"
                label="Retype Password"
                type="Password"
                outlined
                dense
              ></v-text-field>

              <v-combobox
                dense
                label="Referred by"
                outlined
                clearable
                :loading="gettingAll"
                v-model="data.referred_by"
                :items="confUsers"
              >
              </v-combobox>

              <div class="d-flex align-start mb-5">
                <v-checkbox
                  v-model="data.agreeTermsAndConditions"
                  :rules="[rules.required]"
                  class="mt-0"
                  hide-details
                  dense
                ></v-checkbox>

                <div>
                  By ticking, you are confirming that you have read, understood
                  and agree to our
                  <a href="#" @click.prevent="$refs.termsDialog.showDialog()"
                    >Terms and Conditions</a
                  >.
                </div>
              </div>

              <v-btn
                color="primary white--text"
                :loading="status.adding"
                @click="validateForm()"
                depressed
                block
                large
                >Submit</v-btn
              >

              <div class="mt-3 text-center">
                Already a member?
                <router-link :to="{ name: 'Login' }">Login here</router-link>.
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <TermsDialog ref="termsDialog" />

    <!-- Pop up after signup -->
    <!-- <v-dialog v-model="popup" max-width="500">
      <v-card>
        <v-card-title class="headline">Success!</v-card-title>
        <v-card-text>
          <p>
            Success! Check your email (including junk folder), for future
            emails. Save MEALZEE as a safe sender to receive important future
            emails.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="role == 'MEALZEE for Personal Use'"
            color="primary"
            @click="$router.push({ name: 'Goal' })"
            >Okay</v-btn
          >
          <v-btn
            v-else
            color="primary"
            @click="$router.push({ name: 'MealPlans' })"
            >Okay</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-container>
</template>

<script>
import rules from '@/rules';
import db from '@/firebase/init';
import { mapState, mapActions } from 'vuex';

import TermsDialog from './components/TermsDialog.vue';

export default {
  components: { TermsDialog },

  name: 'Register',

  metaInfo: {
    title: 'Register',
  },

  data() {
    return {
      rules,
      menu: false,
      menu1: false,
      invite: null,
      items: [
        // 'MEALZEE for Personal Use',
        'MEALZEE for Dietitians',
        'MEALZEE for Health & Fitness Professionals',
        'MEALZEE for Nutritionists',
        'MEALZEE for Naturopaths',
        'MEALZEE for Students',
      ],
      items1: [
        'Student Fitness Professional',
        'Student Nutrition Professional',
      ],
      role: '',
      role1: '',
      popup: false,
      stupidity: false,
      getting: false,
      errorMessage: null,
      showPassword: false,
    };
  },

  computed: {
    ...mapState({
      data: (state) => state.register.data,
      status: (state) => state.register.status,
      users: (state) => state.members.allUsers,
      gettingAll: (state) => state.members.status.gettingAll,
      organisations: (state) => state.organisation.organisations,
    }),

    confUsers: function () {
      if (!this.users.length) return [];

      return this.users
        .map((user) => `${user.firstName} ${user.lastName}`)
        .sort();
    },

    confOrgs: function () {
      if (!this.organisations.length) return [];

      return this.organisations.map((org) => ({
        name: org.name,
        id: org.id,
        logo: org.logo,
        hexCode: org.hexCode,
      }));
    },
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    menu1(val) {
      val && setTimeout(() => (this.$refs.picker1.activePicker = 'YEAR'));
    },
    role(val) {
      this.$store.commit('register/setDataFieldValue', {
        field: 'isStudent',
        value: false,
      });
      if (val !== 'MEALZEE for Personal Use') {
        this.stupidity = true;

        this.$store.commit('register/setDataFieldValue', {
          field: 'status',
          value: 'pending',
        });

        if (val === 'MEALZEE for Dietitians')
          this.$store.commit('register/setDataFieldValue', {
            field: 'role',
            value: 'dietitian',
          });
        else if (val === 'MEALZEE for Nutritionists')
          this.$store.commit('register/setDataFieldValue', {
            field: 'role',
            value: 'nutritionist',
          });
        else if (val === 'MEALZEE for Naturopaths')
          this.$store.commit('register/setDataFieldValue', {
            field: 'role',
            value: 'naturopath',
          });
        else
          this.$store.commit('register/setDataFieldValue', {
            field: 'role',
            value: 'personal_trainer',
          });
      } else {
        this.$store.commit('register/setDataFieldValue', {
          field: 'status',
          value: '',
        });

        this.$store.commit('register/setDataFieldValue', {
          field: 'role',
          value: '',
        });

        this.stupidity = false;
      }
    },
    role1(val) {
      this.$store.commit('register/setDataFieldValue', {
        field: 'status',
        value: '',
      });
      this.$store.commit('register/setDataFieldValue', {
        field: 'isStudent',
        value: true,
      });
      this.$store.commit('register/setDataFieldValue', {
        field: 'secondaryRole',
        value: val,
      });
      this.$store.commit('register/setDataFieldValue', {
        field: 'role',
        value: `${val == 'Student Nutrition Professional' ? 'dietitian' : 'personal_trainer'}`,
      });
    },
  },

  methods: {
    ...mapActions('register', ['registerUser']),

    ...mapActions('members', ['getAllUsers']),

    validateForm() {
      if (this.$refs.signupForm.validate()) {
        Promise.resolve(this.registerUser()).then(() => {
          if (this.role == 'MEALZEE for Personal Use')
            this.$router.push({ name: 'Goal' });
          else this.$router.push({ name: 'MealPlans' });
        });

        // setTimeout(() => {
        //   this.popup = true;
        // }, 2500);
      }
    },
  },

  mounted() {
    this.getAllUsers();
    this.$store.commit('register/resetState');

    if (this.$route.query.ref) {
      this.$store.commit('register/setDataFieldValue', {
        field: 'referee',
        value: this.$route.query.ref,
      });
    }

    if (this.$route.query && this.$route.query.invite) {
      let id = this.$route.query.invite;
      this.getting = true;

      db.collection('invites')
        .doc(id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.invite = doc.data();

            db.collection('users')
              .where('email', '==', this.invite.email)
              .limit(1)
              .get()
              .then((snapshot) => {
                if (!snapshot.size) {
                  this.invite.id = doc.id;
                  this.invite.ref = doc.ref;
                  this.getting = false;
                  this.$store.commit(
                    'register/setInviteDate',
                    this.invite.email
                  );
                } else {
                  this.getting = false;
                  this.invite = 'error';
                  this.errorMessage = 'User already exists.';
                }
              });
          } else {
            this.getting = false;
            this.invite = 'error';
            this.errorMessage = 'Invalid invitation ID.';
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.$store.dispatch('showError', error.message);
        });
    }
  },
};
</script>
